import { Suspense } from "react"
import Spinner from "./Spinner"
import SideBar from "./SideBar"
import Header from "./Header"


export const MainLayout = ({children}) => {

    return (<div className="wrapper ">
      <Header />
        <SideBar />
    <main className={`main-content main-layout-background h-100vh `}>
          <Suspense fallback={<Spinner />}>
            {children}
          </Suspense>
        </main>
    </div>)

}