import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice'; // Import the authSlice reducer

// Configure the Redux store
const store = configureStore({
  reducer: {
    auth: authReducer, // Add the auth reducer
  },
});

export default store;