export const ApiEndPoints = {
    GET_BUS_LIST: "bus",
    GET_BUS_LIST_BY_SEARCH: "bus/search",
    ADD_BUS: "bus/create",
    GET_BUS_BY_ID: "bus/getById",
    DELETE_BUS: "bus/delete",
    EDIT_BUS: "bus/edit",
    GET_SEARCH_BUS: "bus/search",
    GET_CITY_LIST: "city",
    GET_CITY_LIST_BY_SEARCH: "city/search",
    ADD_CITY: "city/create",
    GET_CITY_BY_ID: "city/getById",
    DELETE_CITY: "city/delete",
    EDIT_CITY: "city/edit",
    GET_SEARCH_CITY: "city/search",
    GET_ADDRESS_LIST: "address",
    GET_ADDRESS_LIST_BY_SEARCH: "address/search",
    ADD_ADDRESS: "address/create",
    GET_ADDRESS_BY_ID: "address/getById",
    DELETE_ADDRESS: "address/delete",
    EDIT_ADDRESS: "address/edit",
    GET_SEARCH_ADDRESS: "address/search",
    CREATE_TICKETS: "tickets/create",
    GET_TICKETS_LIST: "tickets",
    GET_TICKETS_LIST_BY_SEARCH: "tickets/search",
    GET_TICKETS_BY_ID: "tickets/getById",
    DELETE_TICKETS: "tickets/delete",
    EDIT_TICKETS: "tickets/edit",
    GET_SEARCH_TICKETS: "tickets/search",
    UPDATE_BOOKING_STATUS: "tickets/update-status",
    VERIFY_TICKETS_BY_OTP: "tickets/verifyByOtp",
    GET_DRIVER_LIST: "driver",
    ADD_DRIVER: "driver/create",
    GET_DRIVER_BY_ID: "driver/getById",
    DELETE_DRIVER: "driver/delete",
    EDIT_DRIVER: "driver/edit",
    CREATE_DIRECT_TICKET: "directTickets/create",
    GET_DIRECT_TICKET: "directTickets",
    DELETE_DIRECT_TICKET: "directTickets/delete",
    
}