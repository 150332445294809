import {
  AppBar,
  Badge,
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useTranslation } from "react-i18next";
import { ApiEndPoints } from "../Apis/ApiEndPoints";
import portalTheme from "../Themes/App.thems";
import { fetchList } from "../Apis/Services/CommanApiService";
import NotificationDialog from "./DialogComponent/NotificationDialog";
import { makeStyles } from "@mui/styles";
import { getSidebarFailed, getSidebarSuccess } from "../Reducer/authSlice";
import { SampleLogo } from "../Accests/Images";

const useStyles = makeStyles(() => ({
  Header: {
    zIndex: "1000 !important",
    padding: "15px 10px",
    // paddingLeft: "260px",
    height: "auto",
    boxShadow: " inset 0px -1px 0px #DFE5F1 !important",
    display: "flex !important",
    justifyContent: "center",
    backgroundColor: "#FFFFFF !important",
    [portalTheme.breakpoints.down("md")]: {
      paddingLeft: "0px",
    },
    "& .MuiToolbar-root": {
      padding: "5px 30px",
    },
    "& .logo": {
      "& img": {
        height: 22,
        width: "auto",
      },
      [portalTheme.breakpoints.up("sm")]: {
        "& img": {
          height: "auto",
          width: "auto",
        },
      },
    },
    "& .MuiAvatar-root": {
      width: 20,
      height: 20,
      [portalTheme.breakpoints.up("sm")]: {
        width: "auto",
        height: "auto",
      },
    },
  },
  MainWrapper: {
    gap: "10px",
    display: "flex",
    margin: "0 10px 0 10px",
    flexWrap: "wrap",
    justifyContent: "space-between",
    "& .search-icon": {
      display: "flex",
      maxWidth: "93px",
      alignItems: "center",
    },
    "& .justify-content-between": {
      width: "260px",
    },
    "& .mx-120px": {
      maxWidth: "120px",
      width: "120px",
      justifyContent: "space-between",
    },
  },
  SearchBtn: {
    width: 17,
    height: 17,
    margin: "0 20px 0 0",
    [portalTheme.breakpoints.up("sm")]: {
      width: 18,
      height: 18,
    },
  },
  NotificationIcon: {
    width: "23px!important",
    height: "23px!important",
    [portalTheme.breakpoints.up("sm")]: {
      width: 25,
      height: 25,
    },
  },
  SearchField: {
    border: "none !important",
    width: "100px",
  },
  TextField: { width: "57px", height: "24px" },

  // Select: {
  //   "&:before": {
  //     borderColor: "white",
  //   },
  //   "&:after": {
  //     borderColor: "white",
  //   },
  // },
  ProfileBtn: {
    marginLeft: "15px",
    "& .MuiAvatar-root": {
      width: 34,
      height: 34,
      fontSize: "0.8rem",
      backgroundColor: [portalTheme.palette.primary.main],
      color: [portalTheme.palette.secondary.contrastText],
      [portalTheme.breakpoints.up("md")]: {
        width: 48,
        height: 48,
        fontSize: "1.2rem",
        lineHeight: 1,
      },
    },
  },
  UserDropDown: {
    minWidth: 204,
    marginTop: 6,
    background: "white",
    "& .header": {
      padding: "12px 16px",
      borderBottom: "1px solid #E9E9E9",
      [portalTheme.breakpoints.up("sm")]: {
        padding: "12px 22px",
      },
    },
    "& .usermenu": {
      padding: "10px 12px",
      color: [portalTheme.palette.text.primary],
      [portalTheme.breakpoints.up("sm")]: {
        padding: "13px 16px",
      },
      "& .usermenu-icon": {
        marginRight: 10,
        "& .MuiAvatar-root": {
          width: 18,
          height: 18,
          [portalTheme.breakpoints.up("sm")]: {
            width: 22,
            height: 22,
          },
        },
      },
    },
  },
}));

const Header = (props) => {
  const classes = useStyles();

  const sidebarIn = useSelector((state) => state?.auth?.sidebarIn);


  const [openNotificationDialog, setOpenNotificationDialog] = useState(false);

  const [notificationCount, setNotificationCount] = useState({total:0});

  const getNotificationCount = async () => {
    const result = await fetchList(ApiEndPoints.GET_DIRECT_TICKET);
    if (result?.status === 200) {
      setNotificationCount(result);
    } else {
      setNotificationCount({total:0});
    }
  };

  console.log(notificationCount);

  const handleCloseNotificationDialog = async () => {
    setOpenNotificationDialog(false);
    getNotificationCount();
  };

  useEffect(() => {
    getNotificationCount();
  }, []);


  const { t, i18n } = useTranslation();
  const Langauge = [
    { name: "English", value: "en" },
    { name: "Gujarati", value: "gu" },
  ];
  const [Langauges, setLangauges] = useState(
    localStorage.getItem("lng") || "en"
  );

  const handleChangeLng = (e) => {
    i18n.changeLanguage(e.target.value);
    localStorage.setItem("lng", e.target.value);
    setLangauges(e.target.value);
  };

  const dispatch = useDispatch();

  const toggleSidebar = () => { 
    if (document.body.classList.contains("show-sidebar")) {
      document.body.classList.remove("show-sidebar");
      dispatch(getSidebarFailed());
    } else {
      document.body.classList.add("show-sidebar");
      dispatch(getSidebarSuccess());
    }
  }

  console.log(sidebarIn, 314);



  return (
    <AppBar
      className={`${classes.Header} header `}
      id={"headerone"}
      sx={{paddingLeft:sidebarIn?"10px" :"256px"}}
      position="fixed"
      color="default"
    >
      <Box className={classes.MainWrapper} id={"headerone"}>
        <div className="d-flex align-items-center">
          {sidebarIn && 
           (  
           <>
          <IconButton
           onClick={toggleSidebar}
            // sx={{
            //   backgroundColor: "#382DAE",
            //   color: "#ffffff",
            //   ":hover": { backgroundColor: "#382DAE" },
            // }}
          >
            <MenuOutlinedIcon />
          </IconButton>
           <img style={{height:"50px", marginLeft:"10px", marginBottom:"10px"}} src={SampleLogo} alt="Logo" />
          
           </> 
           ) 
          } 
        </div>
        <div>
          <div className="d-flex gap-3 justify-content-end align-items-center w-100">
            <FormControl
              style={{
                justifyContent: "center",
                textTransform: "capitalize",
                // left: "965px"
                // display:"none"
              }}
            >
              <InputLabel id="demo-simple-select-label">
                {t("Langauge")}
              </InputLabel>
              <Select
                onChange={handleChangeLng}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Langauge"
                value={Langauges}
                sx={{ borderRadius: "12px" }}
                // defaultValue={localStorage.getItem("lng")}
              >
                {Langauge.map((element) => (
                  <MenuItem value={element.value} key={element.name}>
                    {element.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="outlined"
              className="comman-button"
              onClick={() => setOpenNotificationDialog(true)}
            >
              <Badge
                sx={{
                  [`& .MuiBadge-badge`]: {
                    backgroundColor: "#000",
                    color: "#ffffff",
                    right: "-16px",
                  },
                }}
                badgeContent={notificationCount?.total?.toString()}
              >
                <NotificationsIcon sx={{ width: "20px", height: "20px" }} />
              </Badge>
            </Button>
          </div>
        </div>
      </Box>

      {openNotificationDialog && (
        <NotificationDialog
          openNotificationDialog={openNotificationDialog}
          handleCloseNotificationDialog={handleCloseNotificationDialog}
          getNotificationList={getNotificationCount}
          notificationList = {notificationCount}
        />
      )}
    </AppBar>
  );
};

export default Header;
