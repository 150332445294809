import { useTranslation } from "react-i18next";
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  Divider,
  Drawer,
  FormControl,
  Grid2 as Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router-dom";
import {
  createRecord,
  deleteRecord,
  fetchList,
} from "../../Apis/Services/CommanApiService";
import { ApiEndPoints } from "../../Apis/ApiEndPoints";
import Spinner from "../Spinner";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const NotificationDialog = (props) => {
  let {
    openNotificationDialog,
    handleCloseNotificationDialog,
    getNotificationList,
    notificationList,
  } = props;

  let { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [searchFilters, setSearchFilters] = useState({
    startDate: moment().subtract(15, "days").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    notification_type: 0,
    is_read: 0,
  });

  const handleDiscardNotify = async (id) => {
    setLoading(true);
    const result = await deleteRecord(id, ApiEndPoints.DELETE_DIRECT_TICKET);
    if (result?.status === 200) {
      setLoading(false);
      toast.success(t(result.message));
      getNotificationList();
    } else {
      setLoading(false);
      toast.error(t("Something error..."));
    }
  };

  return (
    // <DialogForm
    //   scroll="paper"
    //   // maxWidth="lg"
    //   maxWidth="md"
    //   title={t("Notifications")}
    //   openDialog={openNotificationDialog}
    //   handleDialogClose={() => {
    //     handleCloseNotificationDialog();
    //     setNotificationList([]);
    //     setPage(1);
    //     setCount(0);
    //   }}
    //   bodyContent={
    <Drawer
      // variant="persistent"
      anchor={"right"}
      size={"md"}
      open={openNotificationDialog}
      onClose={() => {
        handleCloseNotificationDialog();
      }}
    >
      <DrawerHeader id="green-back" sx={{ minWidth: "360px" }}>
        <IconButton
          onClick={() => {
            handleCloseNotificationDialog();
          }}
        >
          {<ChevronRightIcon sx={{ color: "#ffffff!important" }} />}
        </IconButton>
        <Typography sx={{ color: "#ffffff!important" }} variant="h3">
          {t("Notification")}
        </Typography>
      </DrawerHeader>
      <Divider />
      <div className="p-2" style={{ overflowY: "auto" }}>
        {loading && <Spinner />}

        {/* {(!(searchFilters?.is_read !== 0 || notificationList?.length === 0)) && (
            <div className="mt-1 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center ">
                <Checkbox
                  checked={
                    selectedNoti?.length === notificationList?.length &&
                    notificationList?.length !== 0
                  }
                  onChange={(e) => {
                    e.target.checked
                      ? setSelectedNoti(
                          notificationList?.map((item) => item?.id)
                        )
                      : setSelectedNoti([]);
                  }}
                />
                {selectedNoti?.length === 0
                  ? t("Select All")
                  : selectedNoti?.length + t(" Selected")}
              </div>

              <div className="pr-1">
                <span
                  style={{ color: "#205A74", cursor: "pointer" }}
                  onClick={() => handleDiscardNotify(0, 0)}
                >
                  {t("Selected Mark As Read")}
                </span>
              </div>
            </div>
          )} */}

        {notificationList?.data
          ?.slice()
          .reverse()
          ?.map((item, ix) => (
            <React.Fragment key={ix}>
              <div className="py-1">
                <Alert
                  sx={{
                    border: "1px solid #dfdfdf",
                    "&:hover": {
                      background: "#efefef",
                    },
                    color: "#000000",
                  }}
                  variant="outlined"
                  severity="info"
                  action={
                    searchFilters?.is_read === 0 && (
                      <div className="h-100 d-flex align-items-end">
                        <Button
                          onClick={() => handleDiscardNotify(item?._id)}
                          color="inherit"
                          className="comman-button"
                          size="small"
                        >
                          {t("Done")}
                        </Button>
                      </div>
                    )
                  }
                >
                  {" "}
                  <AlertTitle
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      item?.passenger_mo
                        ? window.location.assign(`tel:${item?.passenger_mo}`)
                        : toast.error("Phone number is not available")
                    }
                  >
                    <span id="font-600">{t("Mobile")} :</span>{" "}
                    {item?.passenger_mo}
                  </AlertTitle>
                  <div>
                    <span id="font-600">{t("From")} :</span>{" "}
                    {item?.arrival_city}
                  </div>
                  <div>
                    <span id="font-600">{t("To")} :</span>{" "}
                    {item?.departure_city}
                  </div>
                  <div
                    className="d-flex justify-content-start mt-1"
                    style={{
                      fontSize: "smaller",
                    }}
                  >
                    {moment(item?.createdAt)?.calendar()}
                  </div>
                </Alert>
              </div>
            </React.Fragment>
          ))}

        {notificationList?.total === 0 && (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "350px" }}
          >
            {t("Empty")}
          </div>
        )}
      </div>
    </Drawer>
    // {/* //   } */}
    // {/* // /> */}
  );
};

export default NotificationDialog;
