// import { useLocation } from 'react-router-dom';
import axios from "axios";
import { BASE_URL } from "../Config/Urls";

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 180000,
  params: {},
});

let token = "";


instance.interceptors.request.use(
    function (config) {
      if (token) {
        // config.headers["Authorization"] = "Bearer " + token;
        config.headers.Authorization = token;
      }
      // console.log("++++++++++Request Config++++++++++", JSON.stringify(config));
      return config;
    },
    function (error) {
      // return Promise.reject(error);
      console.log("error from interceptor request", error);
      return Promise.reject(error);
      // return error;
    }
  );

instance.interceptors.response.use(
    function (response) {
      // console.log('++++++++++Response Config++++++++++', response);
      return response;
    },
    function (error) {
      console.log("error from interceptor response", error);
      if (error.response.status === 400) {
        // toast.error(error.response.data.message);
        return error.response;
      } else {
        // toast.error(error.response.data.message);
        console.log('Error:', error.response.data.message);
      }
  
      return error.response ;
      // if (error) return error;
    }
  );

export default instance;
